<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">รายการสินค้า</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">

        <card card-body-classes="table-full-width">
          <div class="row">
            <label class="col-sm-2 col-form-label" style="text-align: right">เดือน</label>
            <div class="col-sm-3 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Mount">
                <el-option class="select-primary" v-for="data in MountType" :key="data.ID" :label="data.Name"
                  :value="data.ID">
                </el-option>
              </el-select>
            </div>
            <label class="col-sm-1 col-form-label" style="text-align: right">ปี</label>
            <div class="col-sm-3 mb-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Year">
                <el-option class="select-primary" v-for="data in YearType" :key="data.ID" :label="data.Name"
                  :value="data.Name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 mb-2">
              <base-button class="mt-auto" native-type="button" type="success"
                v-on:click="onExport">Export</base-button>
            </div>
          </div>
        </card>
        <card card-body-classes="table-full-width">
          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

              <div class="col-md-3">
                <el-select class="select-primary mb-3" v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-6">
              </div>
              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3" clearable prefix-icon="el-icon-search"
                    placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>
            </div>

            <el-table :data="queriedData">
              <el-table-column :min-width="110" align="left" label="รายละเอียด">
                <div slot-scope="props">

                  <el-tooltip content="ดูรายละเอียด" effect="dark" :open-delay="300" placement="top">
                    <base-button @click.native="handleEdit(props.$index, props.row)" class="edit btn-link"
                      type="warning" size="sm" icon>
                      <i class="tim-icons icon-zoom-split"></i>
                    </base-button>
                  </el-tooltip>

                </div>
              </el-table-column>
              <el-table-column label="รูปภาพ" :min-width="200">
                <div slot-scope="props">

                  <img class="card-img" :src="props.row.Image"
                    onerror="javascript:this.src='img/image-not-found.png'" />
                </div>
              </el-table-column>




              <el-table-column :min-width="200" align="left" label="SKU" prop="SKU">
                <template slot-scope="props">
                  <div style="cursor: pointer;" v-on:click="handleEdit(props.$index, props.row)">
                    <div> {{(props.row.SKU)}}</div>
                    <div> Lazada : {{(props.row.SKU)}}Lazada </div>
                    <!-- <div> Shopee : </div>
                      <div> Tiktok : </div> -->
                  </div>

                </template>
              </el-table-column>




              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label">
              </el-table-column>
              <el-table-column label="สถานะ" :min-width="100">
                <div>
                  <div class="badge badge-success">ACTIVE</div> &nbsp;
                </div>
              </el-table-column>

            </el-table>
          </div>
          <div slot="footer" class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { mapActions, mapState } from "vuex";


export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('users', ['stockData']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
        console.log(this.searchedData)
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      stockItems: {},
      searchQuery: '',
      propsToSearch: ['SKU', 'ProductName', 'Amount'],
      tableColumns: [

        {
          prop: 'ProductName',
          label: 'ชื่อสินค้า',
          minWidth: 200
        },
        {
          prop: 'Amount',
          label: 'จำนวน',
          minWidth: 100
        },

      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      MountType: [
        { ID: 1, Name: "มกราคม" },
        { ID: 2, Name: "กุมภาพันธ์" },
        { ID: 3, Name: "มีนาคม" },
        { ID: 4, Name: "เมษายน" },
        { ID: 5, Name: "พฤษภาคม" },
        { ID: 6, Name: "มิถุนายน" },
        { ID: 7, Name: "กรกฎาคม" },
        { ID: 8, Name: "สิงหาคม" },
        { ID: 9, Name: "กันยายน" },
        { ID: 10, Name: "ตุลาคม" },
        { ID: 11, Name: "พฤศจิกายน" },
        { ID: 12, Name: "ธันวาคม" },
      ],
      YearType: [
        { ID: 2021, Name: "2021" },
        { ID: 2022, Name: "2022" },
        { ID: 2023, Name: "2023" },
        { ID: 2024, Name: "2024" },
        { ID: 2025, Name: "2025" },
        {ID: 2026, Name: "2026" },
      ],
      Mount: null,
      Year: 2025
    };
  },
  created() {
    const date = new Date();
    this.Mount = date.getMonth() + 1;
    this.Year = date.getFullYear();
    this.getStock()
  },
  methods: {
    ...mapActions('users', ['getMeProduct', 'getReportMonthlyProductExport']),
    getStock() {
      this.getMeProduct()
    },
    onExport() {
      const data = {
        Mount: this.Mount,
        Year: this.Year,
        UserID: localStorage.getItem('userID')
      }
      this.getReportMonthlyProductExport(data)
    },
    handleLike(index, row) {
      swal({
        title: `You liked ${row.po}`,
        buttonsStyling: false,
        type: 'success',
        confirmButtonClass: 'btn btn-success btn-fill'
      });

    },
    handleEdit(index, row) {
      this.$router.push('/stock/detail/' + row.ID)
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.orderNo}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.orderNo === row.orderNo
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['SKU', 'ProductName', 'Amount'],
      threshold: 0.3
    });
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.0
        });
        result = this.fuseSearch.search(this.searchQuery);
        console.log(result)
      }
      this.searchedData = result;
      this.pagination.currentPage = 1;

    },
    stockData(val) {
      console.log("stockData", val.items)
      this.tableData = val.items.items
    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
