<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">ประวัติการสั่งบรรจุ</h2>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">

        <card card-body-classes="table-full-width">
          <div class="row">

            <label class="col-sm-1 col-form-label" style="text-align: right">รหัสลูกค้า</label>
            <div class="col-sm-5">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="UserID">
                <el-option class="select-primary" v-for="data in tableDataUser" :key="data.cus_id" :label="data.fullname"
                  :value="data.cus_id">
                </el-option>
              </el-select>
            </div>


            <label class="col-sm-1 col-form-label" style="text-align: right">วันที่</label>
            <div class="col-sm-5">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Day">
                <el-option class="select-primary" v-for="data in DayType" :key="data.ID" :label="data.Name"
                  :value="data.ID">
                </el-option>
              </el-select>
            </div>



            <label class="col-sm-1 col-form-label" style="text-align: right">เดือน</label>
            <div class="col-sm-5 mt-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Mount">
                <el-option class="select-primary" v-for="data in MountType" :key="data.ID" :label="data.Name"
                  :value="data.ID">
                </el-option>
              </el-select>
            </div>
            <label class="col-sm-1 col-form-label" style="text-align: right">ปี</label>
            <div class="col-sm-5 mt-2">
              <el-select class="select-primary" placeholder="---- กรุณาเลือก ----" @input="dispatch" size="large"
                :filterable="true" v-model="Year">
                <el-option class="select-primary" v-for="data in YearType" :key="data.ID" :label="data.Name"
                  :value="data.Name">
                </el-option>
              </el-select>
            </div>
            <div class="col-12 mb-2 mt-3">
              <base-button class="mt-auto" native-type="button" type="success"
                style="text-align: center;float:center;;margin:auto;display:block"
                v-on:click="onSearch">ค้นหา</base-button>
            </div>
          </div>

        </card>

        <card card-body-classes="table-full-width">
          <div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"></div>
            <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

              <div class="col-md-3">
                <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>


              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="ค้นหาวันที่" v-model="searchQueryDate" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>



              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="ค้นหาเลขรายการสั่งซื้อ" v-model="searchQueryOrderNo" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>


              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="ค้นหารหัสลูกค้า" v-model="searchQuerySenderCode" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>


              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="ค้นหาชื่อลูกค้า" v-model="searchQueryCustomerName" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>


              <div class="col-md-3">
                <base-input>
                  <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search"
                    placeholder="ค้นหาชื่อผู้รับ" v-model="searchQueryReceiverName" aria-controls="datatables">
                  </el-input>
                </base-input>
              </div>

              <div class="col-md-6">
              </div>


            </div>
            <el-table :data="queriedData">
              <el-table-column :min-width="135" align="left" label="การกระทำ">
                <div slot-scope="props">

                  <el-tooltip content="ดูรายละเอียด" effect="dark" :open-delay="300" placement="top">
                    <base-button @click.native="handleView(props.$index, props.row)" class="edit btn-link" type="warning"
                      size="sm" icon>
                      <i class="tim-icons icon-bag-16"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>


              <el-table-column label="วันที่" :min-width="180">
                <div slot-scope="props">
                  <div>{{ props.row.date_order }}</div>
                </div>
              </el-table-column>


              <el-table-column label="รหัสลูกค้า" :min-width="100">
                <div slot-scope="props">
                  <div>{{ props.row.sender_code }}</div>
                </div>
              </el-table-column>


              <el-table-column label="เลขรายการสั่งซื้อ" :min-width="150">
                <div slot-scope="props">
                  <div style="cursor: pointer;" v-on:click="handleView(props.$index, props.row)">
                    {{ (props.row.order_no) }}
                  </div>

                </div>
              </el-table-column>



              <el-table-column label="ชื่อลูกค้า" :min-width="100">
                <div slot-scope="props">
                  <div>

                    <el-tooltip :content="returnSvclinesValue(props.row.customer_name)" effect="dark" :open-delay="300"
                      placement="top">
                      <div>{{ props.row.customer_name.substring(0, 15) + ".." }}</div>
                    </el-tooltip>




                  </div>
                </div>
              </el-table-column>


              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label">
              </el-table-column>

              <el-table-column :min-width="150" align="center" label="รูปแบบการส่ง">
                <div slot-scope="props">
                  <div v-if="props.row.send_type === 'ไปรษณีย์ EMS'">
                    <img src="img/img1.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ ลงทะเบียน'">
                    <img src="img/img2.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ไปรษณีย์ COD'">
                    <img src="img/img3.webp" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry'">
                    <img src="img/img4.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry COD'">
                    <img src="img/img5.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Same Day'">
                    <img src="img/img6.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'แมสเซนเจอร์'">
                    <img src="img/img7.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash Express'">
                    <img src="img/img8.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Lazada'">
                    <img src="img/img9.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Shopee'">
                    <img src="img/img10.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'SCG'">
                    <img src="img/img11.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T'">
                    <img src="img/img12.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Flash COD'">
                    <img src="img/img13.jpeg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Best Exress'">
                    <img src="img/img14.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'ORANGE EXPRESS'">
                    <img src="img/img15.png" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'NRF Go to laos'">
                    <img src="img/NRF_LOGO.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Tiktok'">
                    <img src="img/imgNew3.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'J&T COD'">
                    <img src="img/imgNew1.jpg" height="70" />
                  </div>
                  <div v-if="props.row.send_type === 'Kerry Central'">
                    <img src="img/imgNew2.jpg" height="70" />
                  </div>

                </div>
              </el-table-column>


              <el-table-column :min-width="200" align="right" label="สถานะ">
                <div slot-scope="props">
                  <div v-if="props.row.status === 'COMPLETED'">
                    <div class="badge badge-success">COMPLETED</div>
                  </div>
                  <div class="badge badge-warning" v-if="props.row.status === 'TRACKED'">TRACKED</div>
                  <div v-if="props.row.status === 'TRACKED'">{{ props.row.tracking_number }}</div>
                  <div v-if="props.row.send_type !== 'แมสเซนเจอร์' && props.row.status === 'TRACKED'">
                    <base-button class="mt-auto" native-type="button" type="success"
                      v-on:click="onLink(props.row.send_type)">Link
                    </base-button>
                  </div>

                </div>
              </el-table-column>

            </el-table>
          </div>
          <div slot="footer" class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { mapActions, mapState } from "vuex";
import moment from 'moment'


export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapState('orders', ['orderListAdmin']),
    ...mapState('users', ['userData']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length) {
        result = this.searchedData;
      }
      if (this.searchedDataDate.length) {
        result = this.searchedDataDate;
      }
      if (this.searchedDataOrderNo.length) {
        result = this.searchedDataOrderNo;
      }
      if (this.searchedDataSenderCode.length) {
        result = this.searchedDataSenderCode;
      }
      if (this.searchedDataCustomerName.length) {
        result = this.searchedDataCustomerName;
      }
      if (this.searchedDataReceiverName.length) {
        result = this.searchedDataReceiverName;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      searchQueryDate: '',
      searchQueryOrderNo: '',
      searchQuerySenderCode: '',
      searchQueryCustomerName: '',
      searchQueryReceiverName: '',
      propsToSearchDate: ['date_order'],
      propsToSearchOrderNo: ['order_no'],
      propsToSearchSenderCode: ['sender_code'],
      propsToSearchCustomerName: ['customer_name'],
      propsToSearchReceiverName: ['receiver_name'],
      propsToSearch: ['sender_code', 'order_no', 'receiver_name', 'customer_name', 'date_order'],
      tableColumns: [
        {
          prop: 'receiver_name',
          label: 'ชื่อผู้รับ',
          minWidth: 200
        },


      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      fuseSearchDate: null,
      fuseSearchOrderNo: null,
      fuseSearchSenderCode: null,
      fuseSearchCustomerName: null,
      fuseSearchReceiverName: null,
      searchedDataDate: [],
      searchedDataOrderNo: [],
      searchedDataSenderCode: [],
      searchedDataCustomerName: [],
      searchedDataReceiverName: [],
      MountType: [
        { ID: 1, Name: "มกราคม" },
        { ID: 2, Name: "กุมภาพันธ์" },
        { ID: 3, Name: "มีนาคม" },
        { ID: 4, Name: "เมษายน" },
        { ID: 5, Name: "พฤษภาคม" },
        { ID: 6, Name: "มิถุนายน" },
        { ID: 7, Name: "กรกฎาคม" },
        { ID: 8, Name: "สิงหาคม" },
        { ID: 9, Name: "กันยายน" },
        { ID: 10, Name: "ตุลาคม" },
        { ID: 11, Name: "พฤศจิกายน" },
        { ID: 12, Name: "ธันวาคม" },
      ],
      YearType: [
        { ID: 2021, Name: "2021" },
        { ID: 2022, Name: "2022" },
        { ID: 2023, Name: "2023" },
        { ID: 2024, Name: "2024" },
        { ID: 2025, Name: "2025" },
        { ID: 2026, Name: "2026" },
      ],
      DayType: [
        { ID: 0, Name: "ทั้งหมด" },
        { ID: 1, Name: "1" },
        { ID: 2, Name: "2" },
        { ID: 3, Name: "3" },
        { ID: 4, Name: "4" },
        { ID: 5, Name: "5" },
        { ID: 6, Name: "6" },
        { ID: 7, Name: "7" },
        { ID: 8, Name: "8" },
        { ID: 9, Name: "9" },
        { ID: 10, Name: "10" },
        { ID: 11, Name: "11" },
        { ID: 12, Name: "12" },
        { ID: 13, Name: "13" },
        { ID: 14, Name: "14" },
        { ID: 15, Name: "15" },
        { ID: 16, Name: "16" },
        { ID: 17, Name: "17" },
        { ID: 18, Name: "18" },
        { ID: 19, Name: "19" },
        { ID: 20, Name: "20" },
        { ID: 21, Name: "21" },
        { ID: 22, Name: "22" },
        { ID: 23, Name: "23" },
        { ID: 24, Name: "24" },
        { ID: 25, Name: "25" },
        { ID: 26, Name: "26" },
        { ID: 27, Name: "27" },
        { ID: 28, Name: "28" },
        { ID: 29, Name: "29" },
        { ID: 30, Name: "30" },
        { ID: 31, Name: "31" },
      ],
      Mount: null,
      Year: 2025,
      Day: null,
      tableDataUser: [],
      UserID: '',
    };
  },

  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log("DATA1", date2)
    console.log("DATA2", loginDate)
    if (loginDate.length == 0) {
      this.logout()
    } else {
      if (loginDate != date2) {
        this.logout()
      }
    }

    const date = new Date();
    this.Mount = date.getMonth() + 1;

    var id = 'C000'
    if (this.UserID != '') {
      id = this.UserID
    }


    this.Day = 0
    var day = ""
    if (this.Day == 0) {
      day = ""
    } else {
      day = this.Day
    }


    const data = {
      Day: day,
      Mount: this.Mount,
      Year: this.Year,
      UserID: id,
    }
    this.adminPickingConfirmOrders2(data)
    this.getAll()
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('orders', ['adminPickingConfirmOrders2']),
    ...mapActions('users', ['getAll']),
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY (HH.mm)')
      }
    },
    returnSvclinesValue(data) {
      return data;
    },


    onLink(send_type) {

      console.log(send_type)

      if (send_type == ("ไปรษณีย์ EMS")) {
        window.open("https://track.thailandpost.co.th/", '_blank');

      } else if (send_type == ("ไปรษณีย์ ลงทะเบียน")) {
        window.open("https://track.thailandpost.co.th/", '_blank');

      } else if (send_type == ("ไปรษณีย์ COD")) {
        window.open("https://track.thailandpost.co.th/", '_blank');

      } else if (send_type == ("Kerry")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("Kerry COD")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("Kerry Same Day")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("แมสเซนเจอร์")) {
        //
      } else if (send_type == ("Flash Express")) {
        window.open("https://www.flashexpress.co.th/tracking/", '_blank');

      } else if (send_type == ("Lazada")) {
        window.open("https://www.flashexpress.co.th/tracking/", '_blank');

      } else if (send_type == ("Shopee")) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html", '_blank');

      } else if (send_type == ("SCG")) {
        window.open("https://www.scgexpress.co.th/trackin", '_blank');

      } else if (send_type == ("J&T")) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html", '_blank');

      } else if (send_type == ("Flash COD")) {
        window.open("https://www.flashexpress.co.th/tracking/", '_blank');

      } else if (send_type == ("Best Exress")) {
        window.open("https://www.best-inc.co.th/track", '_blank');

      } else if (send_type == ("ORANGE EXPRESS")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');

      } else if (send_type == ("NRF Go to laos")) {
        //
      } else if (send_type == ("Tiktok")) {
        //
      } else if (send_type == ("J&T COD")) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html", '_blank');
      } else if (send_type == ("Kerry Central")) {
        window.open("https://th.kerryexpress.com/th/track/", '_blank');
      }






    },
    onSearch() {
      this.searchQueryDate = ""
      this.searchQueryOrderNo = ""
      this.searchQuerySenderCode = ""
      this.searchQueryCustomerName = ""
      this.searchQueryReceiverName = ""
      this.tableData = []
      var id = 'C000'
      if (this.UserID != '') {
        id = this.UserID
      }


      var day = ""
      if (this.Day == 0) {
        day = ""
      } else {
        day = this.Day
      }

      const data = {
        Day: day,
        Mount: this.Mount,
        Year: this.Year,
        UserID: id,
      }

      this.adminPickingConfirmOrders2(data)
    },
    handleView(index, row) {
      this.$router.push({ name: 'Order Detail', params: { orderNo: row.order_no } })
    },
    dispatch(e) {
      this.$emit('input', e)
    },


  },


  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['sender_code', 'order_no', 'receiver_name', 'customer_name', 'date_order'],
      threshold: 0.3
    });

    this.fuseSearchDate = new Fuse(this.tableData, {
      keys: ['date_order'],
      threshold: 0.3
    });

    this.fuseSearchOrderNo = new Fuse(this.tableData, {
      keys: ['order_no'],
      threshold: 0.3
    });

    this.fuseSearchSenderCode = new Fuse(this.tableData, {
      keys: ['sender_code'],
      threshold: 0.3
    });

    this.fuseSearchCustomerName = new Fuse(this.tableData, {
      keys: ['customer_name'],
      threshold: 0.3
    });

    this.fuseSearchReceiverName = new Fuse(this.tableData, {
      keys: ['receiver_name'],
      threshold: 0.3
    });


  },
  watch: {
    userData(val) {
      this.tableDataUser = val.items.items
      for (let i = 0; i < this.tableDataUser.length; i++) {
        this.tableDataUser[i].fullname = this.tableDataUser[i].cus_id + ' ' + this.tableDataUser[i].firstName + ' ' +
          this.tableDataUser[i].lastName;
      }
    },
    orderListAdmin(val) {

      this.tableData = val.items.data
      console.log(this.UserID)
      if (this.UserID != '') {
        var tableData2 = val.items.data;
        this.tableData = [];
        for (let i = 0; i < tableData2.length; i++) {
          if (tableData2[i].sender_code == this.UserID) {
            this.tableData.push(tableData2[i])
          }
        }

      }


      this.tableData.sort((a, b) => {
        return new Date(b.date_order) - new Date(a.date_order);
      });

      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].date_order = this.format_date(this.tableData[i].date_order)
      }
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
      console.log(this.searchedData)
      this.pagination.currentPage = 1;

    },
    searchQueryDate(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearchDate = new Fuse(this.tableData, {
          keys: this.propsToSearchDate,
          threshold: 0.3
        });
        result = this.fuseSearchDate.search(this.searchQueryDate);
      }
      this.searchedDataDate = result;
      console.log(this.searchedDataDate)
    },
    searchQueryOrderNo(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearchOrderNo = new Fuse(this.tableData, {
          keys: this.propsToSearchOrderNo,
          threshold: 0.0
        });
        result = this.fuseSearchOrderNo.search(this.searchQueryOrderNo);
      }
      this.searchedDataOrderNo = result;
      this.pagination.currentPage = 1;
    },
    searchQuerySenderCode(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearchSenderCode = new Fuse(this.tableData, {
          keys: this.propsToSearchSenderCode,
          threshold: 0.3
        });
        result = this.fuseSearchSenderCode.search(this.searchQuerySenderCode);
      }
      this.searchedDataSenderCode = result;
      this.pagination.currentPage = 1;
    },
    searchQueryCustomerName(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearchCustomerName = new Fuse(this.tableData, {
          keys: this.propsToSearchCustomerName,
          threshold: 0.3
        });
        result = this.fuseSearchCustomerName.search(this.searchQueryCustomerName);
      }
      this.searchedDataCustomerName = result;
      this.pagination.currentPage = 1;
    },
    searchQueryReceiverName(value) {
      let result = this.tableData;
      if (value !== '') {
        this.fuseSearchReceiverName = new Fuse(this.tableData, {
          keys: this.propsToSearchReceiverName,
          threshold: 0.3
        });
        result = this.fuseSearchReceiverName.search(this.searchQueryReceiverName);
      }
      this.searchedDataReceiverName = result;
      this.pagination.currentPage = 1;
    },
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
