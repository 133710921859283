import config from '../config';
import {authHeader} from '@/helpers';


export const userService = {
  login,
  logout,
  Me,
  register,
  register2,
  registerStaff,
  getAll,
  getcustomers,
  getById,
  Approve,
  getUserCode,
  getUserByCode,
  getUserByID,
  getcustomerbyid,
  getLastItemCode,
  getLastItem,
  getMeStock,
  getlocations,
  getMeProduct,
  getMeProductDetail,
  editMeUser,
  getItemByCode,
  getItemCustomerByCode,
  update,
  updatecustomer,
  deleteUser,
  deleteProduct,
  deleteProduct2,
  getAlls,
  getAllStaff,
  TopUpMoney,
  TransactionAdmin,
  Transaction,
  getTransactionSummary,
  getTransactionSummaryUser,
  getTransactionSummaryAdmin,
  getReportBoxAdmin,
  getReportZipCodeAdmin,
  getReportZipCode,
  getReportZipCodeAdminTop10,
  getReportZipCodeTop10,
  getReportProductAdmin,
  getReportProduct,
  getShopAdminTop10,
  getReportProductAdminTop10,
  getReportProductTop10,
  getReportMonthlyOrderAdmin,
  getReportMonthlyOrder,
  getReportMonthlyOrderExport,
  getReportBoxAdminExprot,
  getReportMonthlyProductExport,
  getReportSummaryTransactionExport,
  getDashboard,
  getWallets,
  updateStaff,
  updatePassword,
  delete: _delete
};

function login(username, password) {
  let data = {
    'userName': username,
    'password': password
  }
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/token/issue`, requestOptions)
    .then(handleResponseLogin)
    .then(user => {

      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('auth', JSON.stringify(user));
      }
      return user;
    });
}

function Me() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/me`, requestOptions)
    .then(handleResponse)
    .then(data => {
      localStorage.setItem('users', JSON.stringify(data));
      return data;
    });
}

function TopUpMoney(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/topup`, requestOptions).then(handleResponse);
}

function getMeStock() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/me/product`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getlocations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_master_data/getlocations`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}



function getTransactionSummary(day,month,year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/me/transaction-summary?date=${day}&month=${month}&year=${year}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getWallets(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let user = JSON.parse(localStorage.getItem('users'));
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/wallet?user_id=${user.ID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getTransactionSummaryAdmin(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_summary_transaction?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getTransactionSummaryUser(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_summary_transaction?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getReportZipCodeAdmin(day,month,year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_zipcode?day=${day}&month=${month}&year=${year}&user=C000`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getReportBoxAdmin(day,month,year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_box/report?day=${day}&month=${month}&year=${year}&user=C000`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}




function getReportZipCode(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_zipcode?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}



function getShopAdminTop10(day,month,year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_shop/top10?day=${day}&month=${month}&year=${year}&user=C000`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getReportZipCodeAdminTop10(day,month,year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_zipcode/top10?day=${day}&month=${month}&year=${year}&user=C000`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getReportZipCodeTop10(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_zipcode/top10?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getReportProductAdmin(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_product?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getReportProduct(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_product?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}



function getReportProductAdminTop10(day,month,year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_product/top10?day=${day}&month=${month}&year=${year}&user=C000`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getReportProductTop10(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_product/top10?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getReportMonthlyOrderAdmin(day,month,year) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_monthly_order?day=${day}&month=${month}&year=${year}&user=C000`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getReportMonthlyOrder(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_monthly_order?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });

}



function getReportMonthlyOrderExport(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_monthly_order/export?day=${day}&mon=${month}&yr=${year}&user=${userID}`, requestOptions)
    .then(handleResponseExport)
    .then(data => {
      return data;
    });
}


function getReportBoxAdminExprot(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_box/export_report?day=${day}&month=${month}&year=${year}`, requestOptions)
    .then(handleResponseExport)
    .then(data => {
      return data;
    });
}



function getReportMonthlyProductExport(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_monthly_product/transaction_export?day=${day}&mon=${month}&yr=${year}&user=${userID}`, requestOptions)
    .then(handleResponseExport)
    .then(data => {
      return data;
    });

}


function getReportSummaryTransactionExport(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_summary_transaction/export?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions)
    .then(handleResponseExport)
    .then(data => {
      return data;
    });

}





function getDashboard() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_dashboard`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });

}



function getMeProductDetail(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/me/product/${id}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getMeProduct() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/me/product`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function logout() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  // remove user from local storage to log user out
  return fetch(`${config.apiUrl}/me`, requestOptions)
    .then(handleResponse)
    .then(data => {
      localStorage.removeItem('auth')
      localStorage.removeItem('user')
      localStorage.removeItem('reload')
      return data;
    });

}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(user.user)
  };
  return fetch(`${config.apiUrl}/me/register`, requestOptions).then(handleResponse);
}

function register2(user) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(user.user)
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/newcustomer`, requestOptions).then(handleResponse);
}


function registerStaff(user) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(user.user)
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/newstaff/`, requestOptions).then(handleResponse);
}




function editMeUser(user) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(user.user)
  };
  return fetch(`${config.apiUrl}/me`, requestOptions).then(handleResponse);
}

function Approve(data) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/approve`, requestOptions).then(handleResponse);
}

function getUserCode(id) {

  let data = {
    "id": id
  }
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/users/code`, requestOptions).then(handleResponse);
}


function getUserByID(id){
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function getcustomerbyid(id){
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/getcustomerbyid?ID=${id}`, requestOptions).then(handleResponse);
}


function getUserByCode(id) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/users/code/${id.code}`, requestOptions).then(handleResponse);
}

function getLastItemCode(data) {

  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/users/itemCode/${data.ID}`, requestOptions).then(handleResponse);
}


function getLastItem(data) {
  console.log("cus : " + data.cus)
  console.log("sku : " + data.sku)
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/users/items/${data.cus}/${data.sku}`, requestOptions).then(handleResponse);
}

function getItemCustomerByCode(data) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/items/${data.CustomerNo}/${data.ItemNo}`, requestOptions).then(handleResponse);
}

function getItemByCode(data) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/items/${data.CustomerNo}/${data.ItemNo}`, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getcustomers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/getcustomers`, requestOptions).then(handleResponse);
}


function getAllStaff() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data`, requestOptions).then(handleResponse);
}


function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function update(user ) {
  console.log(user)
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(user.user)
  };

  return fetch(`${config.apiUrl}/users/${user.user.ID}`, requestOptions).then(handleResponse);
}


function updatecustomer(user ) {
  console.log(user)
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(user.user)
  };

  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/updatecustomer`, requestOptions).then(handleResponse);
}



function updateStaff(user ) {
  console.log(user)
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(user.user)
  };

  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/updatestaff/`, requestOptions).then(handleResponse);
}



function updatePassword(data ) {
  console.log(data)
  const requestOptions = {
    method: 'PATCH',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };

  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/changepassstaff/`, requestOptions).then(handleResponse);
}



// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function deleteProduct(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/product/${data.ID}`, requestOptions).then(handleResponse);
}

function deleteProduct2(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_product/changeproductstatus/`, requestOptions).then(handleResponse);
}

function deleteUser(data) {
  console.log(data)
  const requestOptions = {
    method: 'DELETE',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };

  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_user_data/deleteuser/`, requestOptions).then(handleResponse);
}

function getAlls() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function TransactionAdmin(day,month,year,userID){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/admin/transaction?day=${date}&month=${month}&year=${year}&user=${userID}`, requestOptions).then(handleResponse);
}
function Transaction(day,month,year){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/me/transaction?day=${date}&month=${month}&year=${year}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout().then(r => console.log(r));
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}


function handleResponseExport(response) {
  return response.text().then(text => {
    const data = text

    console.log(response.url)

    window.open(response.url, '_blank');

    return data;
  });
}

function handleResponseLogin(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}


