<template>
  <div class="row">
    <!-- Big Chart -->



          <modal
            :show.sync="modal.notice"
            headerClasses="justify-content-center"
          >
            <h4 slot="header" class="title title-up">แจ้งเตือน</h4>

            <div>ยอดเงินเหลือน้อยกว่า 100 กรุณาเติมเงิน</div>


            <div style="justify-content: center;align-items: center;text-align: center;">
              <base-button
                class="mt-3 mr-3 mb-3"
                type="danger"
                v-on:click="modal.notice = false"
                >ปิด
              </base-button>
            </div>




        </modal>


    <div class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="mb-3">

                  <base-button type="success" class="animation-on-hover" @click="updated">Clear Caches</base-button>&nbsp;

              </div>
            </div>

    <div class="row mt-5" v-if="role==='admin' && gender==='superadmin'">
      <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #3498DB; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:18px">จำนวนรายการรอดำเนินการ</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{NEW}}</div>
              </div>

          </card>
        </div>
      </div>

        <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #F1C40F; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:18px">จำนวนรายการรอบรรจุ</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{PICKING}}</div>
              </div>

          </card>
        </div>
      </div>


        <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #E74C3C; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:18px">รายการรอขึ้นรถ</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{PACKED}}</div>
              </div>

          </card>
        </div>
      </div>


        <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #17A589; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:18px">รายการรอคิดค่าส่ง</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{WAIT_TRACKED}}</div>
              </div>

          </card>
        </div>
      </div>





      <div class="col-md-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-12" :class="isRTL ? 'text-right' : 'text-left'">
                <h3 class="card-title">ยอดสั่งบรรจุ</h3>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :gradient-colors="bigLineChart.gradientColors"
              :gradient-stops="bigLineChart.gradientStops"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>


      <div class="col-md-6">

        <div class="col-md-12" style="color:black">
          <card style="background-color: white;">
            <template slot="header">
              <h4 class="card-title">ตาราง 10 อันดับสินค้าขายดีประจำเดือน</h4>
            </template>

              <card card-body-classes="table-full-width">
                <div class="row">
                      <label class="col-sm-2 col-form-label" style="text-align: right">วัน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Day3">
                          <el-option
                            class="select-primary"
                            v-for="data in DayType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">เดือน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Mount3">
                          <el-option
                            class="select-primary"
                            v-for="data in MountType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">ปี</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Year3">
                          <el-option
                            class="select-primary"
                            v-for="data in YearType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.Name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right"></label>
                      <div class="col-sm-4 mb-2">
                        <base-button class="mt-auto" native-type="button" type="success"  v-on:click="onSearch3">ค้นหา</base-button>
                      </div>
                </div>
              </card>


             <div style="overflow-x:auto;">
              <table id="customers">
                <thead>
                  <th style="font-size: 12px;width: 40px;border: 1px solid black"></th>
                   <th style="font-size: 12px;border: 1px solid black">รูปภาพ</th>
                  <th style="font-size: 12px;border: 1px solid black">SKU</th>
                  <th style="font-size: 12px;border: 1px solid black">จำนวนที่ขาย</th>
                </thead>
                 <tbody>
                  <tr v-for="(item,index) in this.tableDataProduct" v-bind:key="index">
                    <td style="font-size: 12px;width: 40px;border: 1px solid black;">{{ index+1}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">
                           <img class="card-img" :src="item.Image"  onerror="javascript:this.src='img/image-not-found.png'" width="50" height="80"/>
                    </td>
                    <td style="font-size: 12px;border: 1px solid black;">
                    <div> {{ item.sku}}</div>
                     <div> {{ item.product_name}}</div>
                    </td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.cnt}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>


      </div>
      <div class="col-md-6">

       <div class="col-md-12" style="color:black">
          <card style="background-color: white;">
            <template slot="header">
              <h4 class="card-title">ตาราง 10 ร้านขายดี</h4>
            </template>

              <card card-body-classes="table-full-width">
                <div class="row">
                      <label class="col-sm-2 col-form-label" style="text-align: right">วัน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Day5">
                          <el-option
                            class="select-primary"
                            v-for="data in DayType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">เดือน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Mount5">
                          <el-option
                            class="select-primary"
                            v-for="data in MountType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">ปี</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Year5">
                          <el-option
                            class="select-primary"
                            v-for="data in YearType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.Name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right"></label>
                      <div class="col-sm-4 mb-2">
                        <base-button class="mt-auto" native-type="button" type="success"  v-on:click="onSearch5">ค้นหา</base-button>
                      </div>
                </div>
              </card>

            <div style="overflow-x:auto;">
              <table id="customers">
                <thead>
                  <th style="font-size: 12px;width: 40px;border: 1px solid black"></th>
                  <th style="font-size: 12px;border: 1px solid black">ร้านค้า</th>
                  <th style="font-size: 12px;border: 1px solid black">จำนวน Order</th>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in this.tableDataShop" v-bind:key="index">
                    <td style="font-size: 12px;width: 40px;border: 1px solid black;">{{ index+1}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.sender}} {{ item.first_name}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.cnt}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </card>
        </div>
        <div class="col-md-12 mt-3" style="color:black">
          <card style="background-color: white;">
            <template slot="header">
              <h4 class="card-title">ตาราง 10 อันดับไปรษณีย์</h4>
            </template>

              <card card-body-classes="table-full-width">
                <div class="row">
                      <label class="col-sm-2 col-form-label" style="text-align: right">วัน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Day4">
                          <el-option
                            class="select-primary"
                            v-for="data in DayType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">เดือน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Mount4">
                          <el-option
                            class="select-primary"
                            v-for="data in MountType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">ปี</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Year4">
                          <el-option
                            class="select-primary"
                            v-for="data in YearType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.Name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right"></label>
                      <div class="col-sm-4 mb-2">
                        <base-button class="mt-auto" native-type="button" type="success"  v-on:click="onSearch4">ค้นหา</base-button>
                      </div>
                </div>
              </card>

            <div style="overflow-x:auto;">
              <table id="customers">
                <thead>
                  <th style="font-size: 12px;width: 40px;border: 1px solid black"></th>
                  <th style="font-size: 12px;border: 1px solid black">รหัสไปรษณีย์</th>
                  <th style="font-size: 12px;border: 1px solid black">จำนวนที่ขาย</th>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in this.tableDataZip" v-bind:key="index">
                    <td style="font-size: 12px;width: 40px;border: 1px solid black;">{{ index+1}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.receiver_zipcode}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.cnt}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </card>
        </div>

      </div>
    </div>

    <div class="row mt-5" v-if="role==='admin' && (gender==='admin_order' || gender==='admin_director')" style="width:100%">

      <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #3498DB; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:16px">จำนวนรายการรอดำเนินการ</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{NEW}}</div>
              </div>

          </card>
        </div>
      </div>

      <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #F1C40F; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:16px">จำนวนรายการรอบรรจุ</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{PICKING}}</div>
              </div>

          </card>
        </div>
      </div>


      <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #E74C3C; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:16px">รายการรอขึ้นรถ</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{PACKED}}</div>
              </div>

          </card>
        </div>
      </div>


      <div class="col-md-3">
        <div class="col-md-12">
          <card style="background-color: #17A589; color:white">

              <div class="card-title" style="color:white;text-align:center;font-size:16px">รายการรอคิดค่าส่ง</div>

              <div class="row">
                <div class="col-sm-12" style="color:white;font-size:28px;text-align:center">  {{WAIT_TRACKED}}</div>
              </div>

          </card>
        </div>
      </div>

    </div>



    <div class="row mt-5" v-if="role!=='admin'">

      <div class="col-md-4">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h3 class="card-title">ข้อมูลร้านค้า</h3>
            </template>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <h5 class="col-sm-12">รหัสลูกค้า</h5>
                <label class="col-sm-12">{{ CustomerCode }}</label>
              </div>
              <div class="row">
                <h5 class="col-sm-12">ที่อยู่</h5>
                <label class="col-sm-12">จังหวัด : {{ CustomerProvince}}</label>
                <label class="col-sm-12">อำเภอ : {{ CustomerAmpure }}</label>
                <label class="col-sm-12">ตำบล : {{ CustomerDistrict }}</label>
                <label class="col-sm-12">รหัสไปรษณีย์ : {{ CustomerZipcode}}</label>
              </div>
              <div class="row">
                <label class="col-sm-12">เบอร์โทร : {{ CustomerMobile }}</label>
              </div>
              <div class="row">
                <label class="col-sm-12">สถานะ : ACTIVE</label>
              </div>
              <div class="row">
                <label class="col-sm-12">ข้อมูลจัดเก็บ : ขนาด กว้าง 1 x สูง 1 x ยาว 1</label>
              </div>

              <div class="row">
                <label class="col-sm-12">ยอดเงินคงเหลือ :

                <label v-if="wallets2>=500" style="color:#138D75; font-size: 24px;">
                   {{wallets}}
                </label>
                 <label v-if="wallets2<500" style="color:#FF0000 ; font-size: 24px;">
                   {{wallets}}
                </label>บาท
                  <base-button class="mt-auto" native-type="button" type="warning">เติมเงิน</base-button>
                </label>


              </div>




            </form>
          </card>
        </div>
      </div>
      <div class="col-md-8">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-12" :class="isRTL ? 'text-right' : 'text-left'">
                <h3 class="card-title">ยอดขายย้อนหลัง 6 เดือน</h3>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :gradient-colors="bigLineChart.gradientColors"
              :gradient-stops="bigLineChart.gradientStops"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>


       <div class="col-md-6">
        <div class="col-md-12" style="color:black">
          <card style="background-color: white;">
            <template slot="header">
              <h4 class="card-title">ตาราง 10 อันดับสินค้าขายดีประจำเดือน</h4>
            </template>


            <card card-body-classes="table-full-width">
                <div class="row">
                      <label class="col-sm-2 col-form-label" style="text-align: right">วัน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Day">
                          <el-option
                            class="select-primary"
                            v-for="data in DayType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>

                      <label class="col-sm-2 col-form-label" style="text-align: right">เดือน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Mount">
                          <el-option
                            class="select-primary"
                            v-for="data in MountType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">ปี</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Year">
                          <el-option
                            class="select-primary"
                            v-for="data in YearType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.Name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                       <label class="col-sm-2 col-form-label" style="text-align: right"></label>
                      <div class="col-sm-4 mb-2">
                        <base-button class="mt-auto" native-type="button" type="success"  v-on:click="onSearch">ค้นหา</base-button>
                      </div>
                </div>
            </card>



             <div style="overflow-x:auto;">
              <table id="customers">
                <thead>
                  <th style="font-size: 12px;width: 40px;border: 1px solid black"></th>
                    <th style="font-size: 12px;border: 1px solid black">รูปภาพ</th>
                  <th style="font-size: 12px;border: 1px solid black">SKU</th>
                  <th style="font-size: 12px;border: 1px solid black">จำนวนที่ขาย</th>
                </thead>
                 <tbody>
                  <tr v-for="(item,index) in this.tableDataProduct" v-bind:key="index">
                    <td style="font-size: 12px;width: 40px;border: 1px solid black;">{{ index+1}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">
                           <img class="card-img" :src="item.Image"  onerror="javascript:this.src='img/image-not-found.png'" width="50" height="80"/>
                    </td>
                    <td style="font-size: 12px;border: 1px solid black;">
                    <div> {{ item.sku}}</div>
                     <div> {{ item.product_name}}</div>
                    </td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.cnt}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>


      </div>
      <div class="col-md-6">
        <div class="col-md-12" style="color:black">
          <card style="background-color: white;">
            <template slot="header">
              <h4 class="card-title">ตาราง 10 อันดับไปรษณีย์</h4>
            </template>

             <card card-body-classes="table-full-width">
                <div class="row">
                      <label class="col-sm-2 col-form-label" style="text-align: right">วัน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Day2">
                          <el-option
                            class="select-primary"
                            v-for="data in DayType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">เดือน</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Mount2">
                          <el-option
                            class="select-primary"
                            v-for="data in MountType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.ID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <label class="col-sm-2 col-form-label" style="text-align: right">ปี</label>
                      <div class="col-sm-4 mb-2">
                        <el-select
                          class="select-primary"
                          placeholder="---- กรุณาเลือก ----"
                          @input="dispatch"
                          size="large"
                          :filterable="true"
                          v-model="Year2">
                          <el-option
                            class="select-primary"
                            v-for="data in YearType"
                            :key="data.ID"
                            :label="data.Name"
                            :value="data.Name"
                          >
                          </el-option>
                        </el-select>
                      </div>
                       <label class="col-sm-2 col-form-label" style="text-align: right"></label>
                      <div class="col-sm-4 mb-2">
                        <base-button class="mt-auto" native-type="button" type="success"  v-on:click="onSearch2">ค้นหา</base-button>
                      </div>
                </div>
            </card>


            <div style="overflow-x:auto;">
              <table id="customers">
                <thead>
                  <th style="font-size: 12px;width: 40px;border: 1px solid black"></th>
                  <th style="font-size: 12px;border: 1px solid black">รหัสไปรษณีย์</th>
                  <th style="font-size: 12px;border: 1px solid black">จำนวนที่ขาย</th>
                </thead>
                 <tbody>
                  <tr v-for="(item,index) in this.tableDataZip" v-bind:key="index">
                    <td style="font-size: 12px;width: 40px;border: 1px solid black;">{{ index+1}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.receiver_zipcode}}</td>
                    <td style="font-size: 12px;border: 1px solid black;">{{ item.cnt}}</td>
                  </tr>
                </tbody>
              </table>
            </div>




          </card>
        </div>

      </div>


    </div>


  </div>
</template>
<script>
import {DatePicker, Option, Select, Table, TableColumn} from 'element-ui';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import TaskList from './TaskList';
import UserTable from './UserTable';
import CountryMapCard from './CountryMapCard';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import zipcode from '@/helpers/zipcode.json'
import swal from 'sweetalert2';
import {mapActions, mapState} from "vuex";
import {BasePagination} from 'src/components';
import moment from 'moment'
import { Modal, BaseAlert } from 'src/components';



let bigChartData = [
  [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
  [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
  [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
]
let bigChartLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

export default {
  components: {
    Modal,
    BaseAlert,
    BasePagination,
    mapActions,
    zipcode,
    mapState,
    LineChart,
    BarChart,
    StatsCard,
    TaskList,
    CountryMapCard,
    UserTable,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      gender: null,
      wallets:0,
      wallets2:0,
      notice:false,
      modal : {
        notice:false,
      },
      PACKED: null,
      COMPLETED: null,
      WAIT_TRACKED: null,
      TRACKED: null,
      NEW: null,
      PICKING: null,
      Day: null,
      Mount: null,
      Year: 2025,
      Day2: null,
      Mount2: null,
      Year2: 2025,
      Day3: null,
      Mount3: null,
      Year3: 2025,
      Day4: null,
      Mount4: null,
      Year4: 2025,
      Day5: null,
      Mount5: null,
      Year5: 2025,
      loading: false,
      CustomerCode: "-",
      CustomerMobile: "-",
      CustomerAddress: "-",
      CustomerProvince: "-",
      CustomerAmpure: "-",
      CustomerDistrict: "-",
      CustomerZipcode: "-",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['barcode'],
      tableColumns: [
        {
          prop: 'ProductName',
          label: 'ชื่อสินค้า',
          minWidth: 200
        },
        {
          prop: 'amount',
          label: 'จำนวน',
          minWidth: 100
        }
      ],
       MountType: [
        {ID: 1, Name: "มกราคม"},
        {ID: 2, Name: "กุมภาพันธ์"},
        {ID: 3, Name: "มีนาคม"},
        {ID: 4, Name: "เมษายน"},
        {ID: 5, Name: "พฤษภาคม"},
        {ID: 6, Name: "มิถุนายน"},
        {ID: 7, Name: "กรกฎาคม"},
        {ID: 8, Name: "สิงหาคม"},
        {ID: 9, Name: "กันยายน"},
        {ID: 10, Name: "ตุลาคม"},
        {ID: 11, Name: "พฤศจิกายน"},
        {ID: 12, Name: "ธันวาคม"},
      ],
       YearType: [
        {ID: 2021, Name: "2021"},
        {ID: 2022, Name: "2022"},
        {ID: 2023, Name: "2023"},
        {ID: 2024, Name: "2024"},
        {ID: 2025, Name: "2025"},
        {ID: 2026, Name: "2026"},
      ],
       DayType: [
        {ID: 0, Name: "ทั้งหมด"},
        {ID: 1, Name: "1"},
        {ID: 2, Name: "2"},
        {ID: 3, Name: "3"},
        {ID: 4, Name: "4"},
        {ID: 5, Name: "5"},
        {ID: 6, Name: "6"},
        {ID: 7, Name: "7"},
        {ID: 8, Name: "8"},
        {ID: 9, Name: "9"},
        {ID: 10, Name: "10"},
        {ID: 11, Name: "11"},
        {ID: 12, Name: "12"},
        {ID: 13, Name: "13"},
        {ID: 14, Name: "14"},
        {ID: 15, Name: "15"},
        {ID: 16, Name: "16"},
        {ID: 17, Name: "17"},
        {ID: 18, Name: "18"},
        {ID: 19, Name: "19"},
        {ID: 20, Name: "20"},
        {ID: 21, Name: "21"},
        {ID: 22, Name: "22"},
        {ID: 23, Name: "23"},
        {ID: 24, Name: "24"},
        {ID: 25, Name: "25"},
        {ID: 26, Name: "26"},
        {ID: 27, Name: "27"},
        {ID: 28, Name: "28"},
        {ID: 29, Name: "29"},
        {ID: 30, Name: "30"},
        {ID: 31, Name: "31"},
      ],
      tableDataZip: [],
      tableDataShop: [],
      tableDataProduct: [],
      tableDataMonthlyOrder: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      PermissionWatch: {},
      role: "",
      statsCards: [
        {
          title: '150GB',
          subTitle: 'Number',
          type: 'warning',
          icon: 'tim-icons icon-chat-33',
          footer: '<i class="tim-icons icon-refresh-01"></i> Update Now'
        },
        {
          title: '+45K',
          subTitle: 'Followers',
          type: 'primary',
          icon: 'tim-icons icon-shape-star',
          footer: '<i class="tim-icons icon-sound-wave"></i></i> Last Research'
        },
        {
          title: '150,000',
          subTitle: 'Users',
          type: 'info',
          icon: 'tim-icons icon-single-02',
          footer: '<i class="tim-icons icon-trophy"></i> Customer feedback'
        },
        {
          title: '23',
          subTitle: 'Errors',
          type: 'danger',
          icon: 'tim-icons icon-molecule-40',
          footer: '<i class="tim-icons icon-watch-time"></i> In the last hours'
        }
      ],
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartData[0]
          }],
          labels: bigChartLabels
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      purpleLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0]
      },
      greenLineChart: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV'],
          datasets: [
            {
              label: 'My First dataset',
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [90, 27, 60, 12, 80]
            }
          ]
        },
        gradientColors: [
          'rgba(66,134,121,0.15)',
          'rgba(66,134,121,0.0)',
          'rgba(66,134,121,0)'
        ],
        gradientStops: [1, 0.4, 0]
      },
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
          datasets: [
            {
              label: 'Countries',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    ...mapState('users',['user' ,'stockData', 'transaction' , 'transaction2' ,   'transaction3'  ,   'transaction4' ,   'transaction5' ,  'transaction7']),
     ...mapState('product', ['productList']),
     ...mapState('orders', ['zipList']),
     
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  created() {
    this.Me()


    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date) {
         this.logout()
      }
    }



    let user = JSON.parse(localStorage.getItem('users'));
    this.gender = user.gender;
    console.log(user.role)
    console.log(user.gender)
      if(user.role === 'admin'){
         this.getProduct()
        const date = new Date();
        this.Mount3 =  date.getMonth() + 1;
        this.Year3 =  date.getFullYear();
        this.Mount4 =  date.getMonth() + 1;
        this.Year4 =  date.getFullYear();
        this.Mount5 =  date.getMonth() + 1;
        this.Year5 =  date.getFullYear();

        this.Day3 = 0
         var day3 = ""
        if (this.Day3 == 0) {
          day3 = ""
        } else {
          day3 =  this.Day3
        }


        const data = {
          Day : day3 ,
          Mount : this.Mount3 ,
          Year : this.Year3 ,
          UserID :localStorage.getItem('userID')
        }


        this.Day4 = 0
         var day4 = ""
        if (this.Day4 == 0) {
          day4 = ""
        } else {
          day4 =  this.Day4
        }

        const data4 = {
          Day : day4 ,
          Mount : this.Mount4 ,
          Year : this.Year4 ,
          UserID :localStorage.getItem('userID')
        }
        this.getReportZipCodeAdminTop10(data4)
        this.getReportProductAdminTop10(data)
        this.getReportMonthlyOrderAdmin(data)


        this.Day5 = 0
        var day5 = ""
        if (this.Day5 == 0) {
          day5 = ""
        } else {
          day5 =  this.Day5
        }
        const data5 = {
          Day : day5 ,
          Mount : this.Mount5 ,
          Year : this.Year5 ,
          UserID :localStorage.getItem('userID')
        }
         this.getShopAdminTop10(data5)


        this.getDashboard()


      }else if(user.role === 'user'){
         this.getMeStock()
         const date = new Date();
        this.Mount =  date.getMonth() + 1;
        this.Year =  date.getFullYear();
        this.Mount2 =  date.getMonth() + 1;
        this.Year2 =  date.getFullYear();

        var day = ""
        if (this.Day == 0) {
          day = ""
        } else {
          day =  this.Day
        }

        const data = {
          Day : day ,
          Mount : this.Mount ,
          Year : this.Year ,
          UserID :localStorage.getItem('userID')
        }

        var day2 = ""
        if (this.Day2 == 0) {
          day2 = ""
        } else {
          day2 =  this.Day2
        }

        const data2 = {
          Day : day2 ,
          Mount : this.Mount2 ,
          Year : this.Year2 ,
          UserID :localStorage.getItem('userID')
        }
        this.getReportZipCodeTop10(data2)
        this.getReportProductTop10(data)
        this.getReportMonthlyOrder(data)
         this.getWallets()
         this.getZipCode()
      }

  },
  methods: {
      ...mapActions('account', ['logout']),
     ...mapActions('product', ['getProduct']),
       ...mapActions('users', ['Me', 'getMeStock',"getWallets", 'getDashboard','getReportMonthlyOrderAdmin' , 'getReportMonthlyOrder','getReportZipCodeAdminTop10' , 'getReportZipCodeTop10' ,'getReportProductAdminTop10' ,'getReportProductTop10' , 'getShopAdminTop10']),
       ...mapActions('orders', ['getZipCode']),
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };


      if( role =='admin' && gender=='superadmin') {
        this.$refs.bigChart.updateGradients(chartData);
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }

      if( role !='admin'){
        this.$refs.bigChart.updateGradients(chartData);
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }

    },
    onSearch(){

      var day = ""
        if (this.Day == 0) {
          day = ""
        } else {
          day =  this.Day
        }

      const data = {
         Day : day ,
          Mount : this.Mount ,
          Year : this.Year ,
          UserID :localStorage.getItem('userID')
        }
        this.getReportProductTop10(data)
    },
     onSearch2(){

       var day = ""
        if (this.Day2 == 0) {
          day = ""
        } else {
          day =  this.Day2
        }

        const data = {
          Day : day ,
          Mount : this.Mount2 ,
          Year : this.Year2 ,
          UserID :localStorage.getItem('userID')
        }
        this.getReportZipCodeTop10(data)
    },
     onSearch3(){

       var day = ""
        if (this.Day3 == 0) {
          day = ""
        } else {
          day =  this.Day3
        }

      const data3 = {
          Day : day ,
          Mount : this.Mount3 ,
          Year : this.Year3 ,
          UserID :localStorage.getItem('userID')
        }
        this.getReportProductAdminTop10(data3)
    },
     onSearch4(){

       var day = ""
        if (this.Day4 == 0) {
          day = ""
        } else {
          day =  this.Day4
        }

        const data4 = {
          Day : day ,
          Mount : this.Mount4 ,
          Year : this.Year4 ,
          UserID :localStorage.getItem('userID')
        }
        this.getReportZipCodeAdminTop10(data4)
    },

    onSearch5(){

       var day = ""
        if (this.Day5 == 0) {
          day = ""
        } else {
          day =  this.Day5
        }

        const data4 = {
          Day : day ,
          Mount : this.Mount5 ,
          Year : this.Year5 ,
          UserID :localStorage.getItem('userID')
        }
        this.getShopAdminTop10(data4)
    },




    updated() {
      console.log('New content is available; please refresh.');
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });

      window.location.reload()
    },
    dispatch(e) {
      this.$emit('input', e)
      this.selected = e
    },
    setItemSelect(data) {
      this.preAddData = this.stockItems.filter(elem => {
        if (elem.id === data) return elem;
      })
    },
    numChange() {

    },
     formatPrice2(value) {
        let val = (value/1).toFixed(2)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatPrice(value) {
        let val = (value/1).toFixed(0)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    AddItem() {
      if (this.Amount === 0) {
        swal({
          title: 'ไม่สามารถเพิ่มสินค้าได้',
          text: `จำนวนสินค้าที่กรอกเป็น 0`,
          type: 'error',
        });
        return;
      }

      if (this.Amount > this.preAddData[0].Amount) {
        swal({
          title: 'ไม่สามารถเพิ่มสินค้าได้',
          text: `จำนวนสินค้าที่กรอกมากกว่าจำนวนสินค้าคงเหลือ`,
          type: 'error',
        });
        return;
      }

      let num = 0;
      let check = false;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.preAddData[0].Barcode === this.tableData[i].barcode) {
          num = num + this.tableData[i].amount;
          check = true;
        }
      }


      if (check === true) {
        let total = this.preAddData[0].Amount - num;

        if (this.Amount > total) {
          swal({
            title: 'ไม่สามารถเพิ่มสินค้าได้',
            text: `จำนวนสินค้าที่กรอกมี่ไม่เพียงพอกับจำนวนสินค้าที่เหลือ`,
            type: 'error',
          });
          return;
        }
      }


      this.tableData.push({
        barcode: this.preAddData[0].Barcode,
        ProductName: this.preAddData[0].ProductName,
        amount: this.Amount
      });
      this.preAddData = {}
      this.ItemSelect = ""
      this.Amount = 0
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    this.role = localStorage.getItem('role');
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
    // this.initBigChart(0);
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  },
  watch :{

    transaction7(val){
      console.log("DATA2222sdsdss",val.items.data)
       this.tableDataShop = val.items.data

    },

     transaction5(val){
      console.log("DATA",val.items.data)
      this.wallets = val.items.data;
      this.wallets2 = val.items.data;
      this.wallets = this.formatPrice2(this.wallets);

      if(parseFloat(val.items.data) < 100){
         this.modal.notice = true
      }
     },

     transaction4(val){


      console.log("DATA",val.items.data.COMPLETED)
      // console.log("DATA",val.items.data)

      this.COMPLETED = this.formatPrice(val.items.data.COMPLETED)
      this.WAIT_TRACKED = this.formatPrice(val.items.data.WAIT_TRACKED)
      this.NEW = this.formatPrice(val.items.data.NEW)
      this.TRACKED = this.formatPrice(val.items.data.TRACKED)
      this.PICKING = this.formatPrice(val.items.data.PICKING)
      this.PACKED = this.formatPrice(val.items.data.PACKED)

    },
    transaction(val){
      console.log("DATA",val.items)
      this.tableDataZip = val.items.data
    },
     productList(val) {
       console.log("AAAAAA")
        this.stockItems = val.items.items
        for (let i = 0; i < this.tableDataProduct.length; i++) {
          for (let ii = 0; ii < this.stockItems.length; ii++) {
              if( this.tableDataProduct[i].bar_code === this.stockItems[ii].SKU){
                this.tableDataProduct[i].Image =  this.stockItems[ii].Image
              }
          }

        }
    },

    stockData(val) {
      this.stockItems = val.items
       console.log("AAAAAA")
      console.log(this.stockItems)

    let user = JSON.parse(localStorage.getItem('users'));
    if(user.role === 'user'){
        for (let i = 0; i < this.tableDataProduct.length; i++) {
            for (let ii = 0; ii < this.stockItems.length; ii++) {
                if( this.tableDataProduct[i].bar_code === this.stockItems[ii].SKU){
                  this.tableDataProduct[i].Image =  this.stockItems[ii].Image
                }
            }

          }
      } else {
        for (let i = 0; i < this.tableDataProduct.length; i++) {
          for (let ii = 0; ii < this.stockItems.length; ii++) {
              if( this.tableDataProduct[i].bar_code === this.stockItems[ii].SKU){
                this.tableDataProduct[i].Image =  this.stockItems[ii].Image
              }
          }

        }
      }


    },

    transaction2(val){
       console.log("BBBBBB")
      console.log("DATA",val.items)
      this.tableDataProduct = val.items.data
       console.log(this.tableDataProduct)
       let user = JSON.parse(localStorage.getItem('users'));
       if(user.role === 'user'){
         for (let i = 0; i < this.tableDataProduct.length; i++) {
          for (let ii = 0; ii < this.stockItems.length; ii++) {
              if( this.tableDataProduct[i].bar_code === this.stockItems[ii].SKU){
                this.tableDataProduct[i].Image =  this.stockItems[ii].Image
              }
          }

        }
       } else {
          for (let i = 0; i < this.tableDataProduct.length; i++) {
            console.log(this.stockItems)
          for (let ii = 0; ii < this.stockItems.length; ii++) {
              if( this.tableDataProduct[i].bar_code === this.stockItems[ii].SKU){
                this.tableDataProduct[i].Image =  this.stockItems[ii].Image
              }
          }

        }
       }

        console.log(this.tableDataProduct)
    },
    transaction3(val){
      console.log("DATA",val.items)
      this.tableDataMonthlyOrder = val.items.data

        var cnt = [];
        var mon = [];
      for (let i = 0; i < this.tableDataMonthlyOrder.length; i++) {

         mon.push( moment(String(this.tableDataMonthlyOrder[i].mon)).format('MMM YYYY'))
         cnt.push(this.tableDataMonthlyOrder[i].cnt)
      }

      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: cnt
        }],
        labels: mon
      };

      if( this.role =='admin' && this.gender=='superadmin') {
       this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = 0;
      }

      if( this.role !='admin'){
       this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = 0;
      }

    },




    user(val){
      console.log(val)
      const zeroPad = (num) => String(num).padStart(3, '0')
      this.CustomerCode = 'C'+zeroPad(val.ID)
      this.CustomerProvince = val.province
      this.CustomerAmpure = val.ampure
      this.CustomerDistrict = val.district
      this.CustomerZipcode = val.zipCode
      this.CustomerMobile = val.phoneNumber


    }
  }
};
</script>
<style>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}
</style>


