import config from '../config';
import {authHeader} from '@/helpers';

export const orderService = {
  save,
  getOrderList,
  getOrderDetail,
  MeOrderDetail,
  getAdminOrderList,
  getAdminPickingOrderList,
  getAdminPackedOrderList,
  getAdminPickingConfirmOrderList,
  getAdminPickingConfirmOrderList2,
  getPickingList,
  getlocation,
  confirmPickingOrder,
  confirmPackedOrder,
  confirmPackingOrder,
  confirmPackingOrder2,
  PickingItem,
  UploadProductImage,
  deleteOrder,
  deleteOrder2,
  getOrderHistoryList,
  getBox,
  getBoxAll,
  getBoxById,
  updateBox,
  updateBoxStatus,
  addBox,
  addboxamount,
  getOrderHistoryListDetail,
  getOrderHistoryListDetail2,
  getOrderHistoryListDetail3,
  getOrderTransaction,
  uploadTrack,
  uploadFileOrder,
  getBarcodeByRec,
  getZipCode,
};


function uploadTrack(value) {

  let user = JSON.parse(localStorage.getItem('users'));
  let formData = new FormData();
  formData.append('file', value);
  formData.append('created_id', user.ID);


  return fetch(`${config.apiUrl}/upload-track`,
    {
        body: formData,
        method: "post",

    }).then(handleResponse);
}


function uploadFileOrder(value) {

  let user =  localStorage.getItem('userID');
  let formData = new FormData();
  formData.append('upload_xlsx', value);
  formData.append('custormer', user);


  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_order_import/`,
    {
        body: formData,
        method: "post",

    }).then(handleResponse);
}

function save(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/me/order`, requestOptions).then(handleResponse);
}


function getBox() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_box/`, requestOptions).then(handleResponse);
}

function getBoxAll() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch('https://nrichfulfillment.com/phpapi/Mooi_box/getallbox', requestOptions).then(handleResponse);
}


function getBoxById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_box/getboxbyid?ID=${id}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}


function getAdminPickingConfirmOrderList2(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_report_order?day=${day}&month=${month}&year=${year}&user=${userID}`, requestOptions).then(handleResponse);
}



function updateBox(data) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/box/${data.ID}`, requestOptions).then(handleResponse);
}

function addboxamount(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_box/addboxamount/`, requestOptions).then(handleResponse);
}


function updateBoxStatus(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_box/updateboxstatus/`, requestOptions).then(handleResponse);
}

function addBox(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_box/newbox/`, requestOptions).then(handleResponse);
}



function getOrderDetail(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/order/${data}`, requestOptions).then(handleResponse);
}

function MeOrderDetail(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/me/order/${data}`, requestOptions).then(handleResponse);
}


function getOrderList(day,month , year) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/me/order?date=${day}&month=${month}&year=${year}`, requestOptions).then(handleResponse);
}


function getOrderHistoryList() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/order-history`, requestOptions).then(handleResponse);
}

function getZipCode() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_master_data/zipcodedb`, requestOptions).then(handleResponse);
}


function getOrderHistoryListDetail(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/order-history`, requestOptions).then(handleResponse);
}


function getOrderHistoryListDetail2(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/order-history-detail`, requestOptions).then(handleResponse);
}

function getOrderHistoryListDetail3(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/order-history-print`, requestOptions).then(handleResponse);
}



function getOrderTransaction(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/order-transaction/${data}`, requestOptions).then(handleResponse);
}

function deleteOrder(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/me/order/${data}`, requestOptions).then(handleResponse);
}

function deleteOrder2(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/order/${data}`, requestOptions).then(handleResponse);
}

function getAdminOrderList() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/order`, requestOptions).then(handleResponse);
}

function getAdminPickingOrderList() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/pickingOrder`, requestOptions).then(handleResponse);
}

function getAdminPackedOrderList() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/packedOrder`, requestOptions).then(handleResponse);
}

function getAdminPickingConfirmOrderList(day,month,year,userID) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/pickingConfirmOrder?date=${day}&month=${month}&year=${year}`, requestOptions).then(handleResponse);
}

function getBarcodeByRec(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/receive/barcode/${data.recNo}`, requestOptions).then(handleResponse);
}

function getPickingList(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/picking/${data}`, requestOptions).then(handleResponse);
}

function getlocation(data) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };
  return fetch(`${config.apiUrl}/admin/location/${data}`, requestOptions).then(handleResponse);
}

function confirmPackedOrder(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/picking`, requestOptions).then(handleResponse);
}

function confirmPickingOrder(data) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/picking`, requestOptions).then(handleResponse);
}


function confirmPackingOrder(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/packing`, requestOptions).then(handleResponse);
}

function confirmPackingOrder2(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`https://nrichfulfillment.com/phpapi/Mooi_order/updateordercompleted/`, requestOptions).then(handleResponse);
}


function PickingItem(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/admin/picking-item`, requestOptions).then(handleResponse);
}

function UploadProductImage(data) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };
  return fetch(`${config.apiUrl}/upload-product-image`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout().then(r => {
          console.log(r)
        });
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
